import { render, staticRenderFns } from "./PensionFraud.vue?vue&type=template&id=69a8040a&scoped=true&"
import script from "./PensionFraud.vue?vue&type=script&lang=ts&"
export * from "./PensionFraud.vue?vue&type=script&lang=ts&"
import style0 from "./PensionFraud.vue?vue&type=style&index=0&id=69a8040a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69a8040a",
  null
  
)

export default component.exports